@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f1f8ffce; */
}

/* Style the scrollbar */
.scrollable-container::-webkit-scrollbar {
  height: 6px; /* Horizontal scrollbar height */
}

.scrollable-container::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
  margin-left: 20px;
  margin-right: 20px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #88888899; /* Thumb color */
  border-radius: 10px; /* Rounded thumb */
  /* margin-left: 50px; */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker thumb on hover */
}

/* Style the scrollbar */
.modal::-webkit-scrollbar {
  width: 6px;
  height: 6px; /* Horizontal scrollbar height */
}

.modal::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
  /* margin-left: 20px;
  margin-right: 20px; */
}

.modal::-webkit-scrollbar-thumb {
  background-color: #88888899; /* Thumb color */
  border-radius: 10px; /* Rounded thumb */
  /* margin-left: 50px; */
}

.modal::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker thumb on hover */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes ani {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
.clouds {
  background: url("https://www.datocms-assets.com/62418/1654790319-illu-carousel-games-icons.png")
    repeat-x 0 / 100% auto;
  background-size: cover;
  animation: ani 10s linear infinite;
}

.rocket-img {
  transition-property: transform;
  transition-duration: 1000ms;
  transform-origin: center center;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rocket-img:hover {
  transform: translateY(10px);
}

.swiper {
  width: 500px;
  height: 380px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
}

.swiper-slide video {
  width: 100%;
  height: 100%;
}

.swiper-slide:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}

.swiper-slide:nth-child(2n) {
  background-color: rgb(0, 140, 255);
}

.swiper-slide:nth-child(3n) {
  background-color: rgb(10, 184, 111);
}

.description {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.description p {
  margin-bottom: 1rem;
}

.description ol {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.description ol li {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.description ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.description ul li {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.loader {
  border-top-color: #3498db;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.ql-container {
  height: 81% !important;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slideIn {
  animation: slideIn 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.opacity-50 {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}
.otherTeam.swiper {
  width: 100%;
}
.otherTeam .swiper-slide {
  background-color: skyblue;
  display: flex;
  color: black;
  flex-direction: column;
  text-align: center;
  padding: 15px 60px;
}

.animated-line {
  width: 2px;
}
/* Ball animation */
.ball {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  margin-left: -7px;
  background-color: black;
  border-radius: 50%;
  border: 2px solid #fff;
  z-index: 0;
  animation: mover 3s infinite alternate;
}

/* custom  */
@media screen and (min-width: 768px) {
  .top:after{
    content: "";
    height: 2px;
    width: 14px;
    /* border: 0px solid #000000; */
    /* margin: auto; */
    position: absolute;
    /* right: 0; */
    left: -6px;
    /* bottom: 0; */
    top: 10px;
    background-color: #111a24;
    z-index: 0;
  }
}
@media screen and (min-width: 768px) {

  .bottem:before{
    content: "";
    height: 2px;
    width: 14px;
    /* margin: auto; */
    position: absolute;
    right: 979px;
    /* left: 0; */
    bottom: -25px;
    /* top: 4px; */
    background-color: #111a24;
    z-index: 0;
  }
}
@media screen and (min-width: 768px) {
  .vartical:after{
    content: "";
    height: 25px;
    width: 2px;
    /* border: 1px solid #000000; */
    /* margin: auto; */
    position: absolute;
    /* right: 978px; */
    left: -5;
    /* bottom: 0; */
    /* top: 4px; */
    background-color: #111a24;
    z-index: 0;
  }
}

.bix-testimonials .inner-banner h4 {
  text-shadow: 1px 1px #111a24, -1px 1px #111a24, 1px -1px #111a24, -1px -1px #111a24;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

/* testimonials section */
.section-testimonials:after {
  content: "";
  height: 180px;
  width: 180px;
  background-image: url(../src/assets/portfolioImages/shape.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin-top: 100px;
}




/* before and after line what i achieve */
/* .bix-achievement .bix-border-r:before {
  content: "";
  height: 6px;
  width: 16px;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
  background-color: #111a24;
  z-index: 0;
} */
/* before and after line what i achieve */
/* .bix-achievement .bix-border-l:before {
  content: "";
  height: 6px;
  width: 16px;
  border: 2px solid #fff;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #111a24;
  z-index: 0;
} */
/* before and after line left*/
/* .bix-achievement .bix-achievement-inner .bix-achievement-box.left:after {
  content: "";
  width: 30px;
  height: 2px;
  position: absolute;
  top: 50%;
  right: -30px;
  background-color: #111a24;
  z-index: -1;
} */
/* before and after line right*/
/* .bix-achievement .bix-achievement-inner .bix-achievement-box.right:after {
  content: "";
  width: 30px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: -30px;
  background-color: #111a24;
  z-index: -1;
} */
/* gradient color */
.mask-linear-gradient {
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    #08182d 128px,
    #08182d calc(100% - 200px),
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent 0%,
    #08182d 128px,
    #08182d calc(100% - 200px),
    transparent 100%
  );
}

/* Keyframes for ball movement */
@keyframes mover {
  0% {
    transform: translateY(-5px); /* Start position */
  }
  100% {
    transform: translateY(90px); /* End position, adjusted for the max height */
  }
}

/* Partners portfolio */
@media screen and (max-width: 1399px) {
  .bix-hero-img .hero-inner-img:before {
    height: 450px;
    width: 450px;
  }
}

.bix-hero-img .hero-inner-img:before {
  content: "";
  background-image: url(../src/assets/team/partners/border-one.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 550px;
  width: 550px;
  margin: auto;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  z-index: -1;
}
.bix-hero-img .hero-inner-img:after {
  content: "";
  height: 500px;
  width: 500px;
  background-image: url(../src/assets/team/partners/shape-one.png);
  background-repeat: no-repeat;
  background-size: 100%;
  margin: auto;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  z-index: -1;
}
.bix-hero-contact:before {
  content: "";
  height: 60px;
  width: 60px;
  background-image: url(../src/assets/team/partners/shape-1.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 30px;
  left: 50px;
  z-index: -1;
}
.bix-hero-contact h1:after {
  content: "";
  height: 70px;
  width: 70px;
  background-image: url(../src/assets/team/partners/shape-two.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: -40px;
  right: 40px;
  z-index: -1;
}
.bix-hero-contact .bix-buttons:after {
  content: "";
  height: 70px;
  width: 33px;
  background-image: url(../src/assets/team/partners/shape-3.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: -40px;
  right: 200px;
  z-index: -1;
}
.bix-hero-img:after {
  content: "";
  height: 60px;
  width: 60px;
  background-image: url(../src/assets/team/partners/shape-4.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.bix-hero-contact:after {
  content: "";
  height: 50px;
  width: 25px;
  background-image: url(../src/assets/team/partners/shape-2.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

/* .bix-skill .skill-inner-box .skill-logo a:after {
  content: "";
  height: 25px;
  width: 67px;
  background-image: url(../src/assets/team/partners/round-shape.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 9px;
  left: -20px;
  z-index: -1;
} */

.bix-about-img .inner-item .box-inner:after {
  content: "";
  height: 30px;
  width: 60px;
  background-image: url(../src/assets/team/partners/round-shape.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: -4px;
  left: -33px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  z-index: 0;
}

@media screen and (max-width: 1199px) {
  .bix-hero-img .hero-inner-img:before {
    height: 400px;
    width: 400px;
  }
}
@media screen and (max-width: 1399px) {
  .bix-hero-img .hero-inner-img:after {
    height: 400px;
    width: 400px;
  }
}
@media screen and (max-width: 1199px) {
  .bix-hero-contact:before {
    top: 0;
    left: 0;
  }
}
@media screen and (max-width: 1199px) {
  .bix-hero-contact h1:after {
    height: 50px;
    width: 50px;
    right: 50px;
  }
}

.section-hero .first-name,
.section-hero .last-name,
.section-about .creative-text {
  text-shadow: 2px 2px #111a24, -2px 2px #111a24, 2px -2px #111a24,
    -2px -2px #111a24;
}

/* Individial protfolio about */
.section-about .container:before {
  content: "";
  height: 50px;
  width: 50px;
  background-image: url(../src/assets/team/partners/Ashape-3.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 150px;
  z-index: -1;
}
